<template>
  <div class="content">
    <el-row class="bgW">
      <el-col :span="22">
        <!--检索条件-->
        <el-form class="search" :inline="true" label-suffix="：" size="mini" :model="search">
          <el-form-item label="客户名称">
            <el-input
              v-model="search.customer_name"
              maxlength="30"
              class="w120"
              clearable
              placeholder="请输入客户名称"
            />
          </el-form-item>
          <el-form-item label="转出门店/门诊">
            <el-select
              v-model="search.out_shop_id"
              clearable
              remote
              filterable
              :remote-method="remoteMethod"
              placeholder="请输入"
              class="w120"
            >
              <el-option
                v-for="item in options.out_shop_id"
                :key="item.shopId"
                :label="item.shopName"
                :value="item.shopId"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="转入门店/门诊">
            <el-select
              v-model="search.in_shop_id"
              clearable
              remote
              filterable
              :remote-method="remoteMethods"
              placeholder="请输入"
              class="w120"
            >
              <el-option
                v-for="item in options.in_shop_id"
                :key="item.shopId"
                :label="item.shopName"
                :value="item.shopId"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="2" style="text-align: right;">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
      </el-col>
    </el-row>
    <div class="accountCon">
      <div class="btnBox">
        <el-button
          v-if="!userInfo.shop_id"
          type="primary"
          :disabled="!idList.length"
          class="mb10"
          size="mini"
          @click="hint('导出选中列表')"
        >导出数据</el-button>
      </div>
      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            border
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
            @selection-change="handleSelectionChange"
          >
            <ElTableColumn v-if="!userInfo.shop_id" type="selection" width="50" />
            <ElTableColumn label="转出门店/门诊" prop="out_shop_name" />
            <ElTableColumn label="转出时间" prop="create_time" />
            <ElTableColumn label="客户名称" prop="customer_name" />
            <ElTableColumn label="操作员工" prop="operator_name" />
            <ElTableColumn label="转入门店/门诊" prop="in_shop_name" />
            <ElTableColumn label="转店原因" prop="reason" />
          </el-table>
          <pagination
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList"
          />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import pagination from "@/components/Pagination";
import { getShopList } from "@/api/shop";
import { transferShopList, transferShopExport } from "@/api/client";

class Search {
  customer_name = ""; // 客户名称
  in_shop_id = "";
  out_shop_id = "";
}
class Options {
  in_shop_id = [];
  out_shop_id = [];
  constructor() {
    this.statistics = [
      { id: 1, name: "选项1" },
      { id: 2, name: "选项2" }
    ];
  }
}
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
export default {
  name: "StaffList",
  components: {
    pagination
  },
  data() {
    return {
      search: new Search(), // 检索条件
      options: new Options(), // 选项
      page: new Page(), // 分页
      list: [], // 数据展示
      idList: ""
    };
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 转出门店
    remoteMethod(e) {
      getShopList({ page: 1, limit: 100000, shopName: e }).then(res => {
        this.options.out_shop_id = res.list;
      });
    },
    // 转入门店
    remoteMethods(e) {
      getShopList({ page: 1, limit: 100000, shopName: e }).then(res => {
        this.options.in_shop_id = res.list;
      });
    },
    // 获取列表
    getList(option) {
      if (option === "search") this.page = new Page();
      transferShopList({ ...this.search, ...this.page }).then(res => {
        this.list = res.list;
        this.page.total = res.dataCount;
      });
    },
    // 点击详情
    tapDetail(id) {
      this.$router.push("./staffMsg");
    },
    // 提示
    hint(text) {
      this.$confirm(`${text},是否确定？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.getExport();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    // 导出
    getExport() {
      transferShopExport({ id: this.idList }).then(res => {
        window.open(res.data.url);
      });
    },
    // 改变表格选中状态
    handleSelectionChange(val) {
      this.idList = val.map(i => {
        return i.id;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.mainColor {
  color: #2dc0a3;
}
.content {
  height: calc(100% - 56px);
  overflow-y: auto;
}
.bgW {
  background: #fff;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 20px;
}

.el-form-item {
  margin: 5px 40px 3px 0;
}
.accountCon {
  background: #fff;
  padding: 0px 10px 80px;
  .btnBox {
    display: flex;
    justify-content: space-between;
  }
}
</style>
